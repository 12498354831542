import React, { useEffect, useState, useContext } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import TuneIcon from '@mui/icons-material/Tune';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Link, useNavigate } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ThemeContext from '../context//ThemeContext';
import TablePagination from '@mui/material/TablePagination';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import useAuth from '../hooks/useAuth';
import instance from '../axios/axios';
import { useParams } from 'react-router-dom';

const ItemType = 'COLUMN';

const DraggableColumn = ({ column, index, moveColumn, isReordering, isFirst, handleRequestSort, isDarkMode }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index },
    canDrag: !isFirst,
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (item) => {
      if (item.index !== index && !isFirst) {
        moveColumn(item.index, index);
        item.index = index;
      }
    },
  });

  return (
    <TableCell
      ref={isReordering && !isFirst ? (node) => ref(drop(node)) : null}
      onClick={() => handleRequestSort(column.accessor)}
      sx={{
        cursor: isReordering && !isFirst ? 'move' : 'pointer',
        width: '10%',
        position: isFirst ? 'sticky' : 'relative',
        left: isFirst ? 0 : 'auto',
        zIndex: isFirst ? 2 : 1,
        backgroundColor: isFirst ? (isDarkMode ? '#1e1e1e' : 'white') : 'inherit', // Sticky background for first column
        color: isDarkMode ? 'white' : '#1e1e1e', // Text color based on mode
        borderRight: isFirst ? '1px solid #E0E0E0' : 'none',
      }}
    >
      {column.Header}
    </TableCell>
  );
};

function DynamicTable({ data, columns: initialColumns, hasChildren, childReports, startDate, endDate, onDrillThrough }) {
    const { isDarkTheme } = useContext(ThemeContext);
    const { auth, setAuth } = useAuth();
    const [columns, setColumns] = useState(initialColumns);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(initialColumns[0].accessor);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentLinks, setCurrentLinks] = useState([]);
    const [isReordering, setIsReordering] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [newHeaders, setNewHeaders] = useState({});
    const [checked, setChecked] = useState(true);

    const navigate = useNavigate();

    const { reportId } = useParams();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpenDialog = (links) => {
        setCurrentLinks(links);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => setDialogOpen(false);

    const formatValue = (column, value) => {
        if (column.Header.includes('$')) {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
        } else if (column.Header.includes('%')) {
            return `${value}%`;
        }
        return value;
    };

    const renderCellContent = (column, row) => {
        if (column.Header.includes('*')) {
            console.log(row[column.accessor]);
            return (
                <a href={row[column.accessor]} target="_blank" rel="noopener noreferrer">{row[column.accessor]}</a>
            )
        }
        if (!hasChildren) {
            return formatValue(column, row[column.accessor]);
        }

        const linkedReports = childReports.filter(
            (child) => child.parent_column_with_link === column.accessor
        );

        const formattedValue = formatValue(column, row[column.accessor]);

        if (linkedReports.length > 1) {
            return (
                <Button color='primary'><a
                style={{ cursor: 'pointer' }}
                onClick={() =>
                    handleOpenDialog(
                        linkedReports.map((report) => {
                            const urlParams = new URLSearchParams();
                            report.linked_params.forEach(param => {
                                urlParams.append(param.child_key, row[param.parent_value]);
                            });
                            return {
                                path: `/report/${report.child_report_id}?${urlParams.toString()}&start_date=${startDate}&end_date=${endDate}`,
                                label: report.child_report_label,
                                name: report.child_report_name,
                            };
                        })
                    )
                }
                >
                {formattedValue}
                </a>
                </Button>
            );
        } else if (linkedReports.length === 1) {
            const report = linkedReports[0];
            const urlParams = new URLSearchParams();
            report.linked_params.forEach(param => {
                urlParams.append(param.child_key, row[param.parent_value]);
            });
            const drillThroughUrl = `/report/${report.child_report_id}?${urlParams.toString()}&start_date=${startDate}&end_date=${endDate}`;

            return (
                <Button color='primary'><Link to={drillThroughUrl} style={{ textDecoration: 'none', color: 'inherit' }}>
                {formattedValue}
                </Link>
                </Button>
            );
        }
        return formattedValue;
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const moveColumn = (fromIndex, toIndex) => {
        const updatedColumns = Array.from(columns);
        const [movedColumn] = updatedColumns.splice(fromIndex, 1);
        updatedColumns.splice(toIndex, 0, movedColumn);
        setColumns(updatedColumns);
    };

    const handleEditHeadersClick = () => {
        setEditDialogOpen(true);
    };

    const handleRemovePreferences = async () => {
        try {
            const response = await instance.post(`/reports/preferences/remove`, {  module: 'Reports', record_id: reportId }, { withCredentials: true });
            if (response.status === 200) {
                console.log('Preferences removed successfully');
                alert('Preferences removed successfully\nPlease refresh the page to see the changes.');
            } else {
                console.error('Error removing preferences', response);
            }
        } catch (error) {
            console.error('Error removing preferences', error);
        }
    };

    const handleSaveHeaders = async () => {
        try {
            const response = await instance.post('/reports/preferences/save', {
                module: 'Reports',
                record_id: reportId, // Update this with the actual record_id
                preferences: {
                    column_names: newHeaders
                }
            }, { withCredentials: true });

            if (response.status === 200) {
                setColumns(columns.map(col => ({
                    ...col,
                    Header: newHeaders[col.accessor] || col.Header
                })));
                setEditDialogOpen(false);
            } else {
                console.error('Error saving header preferences', response);
            }
        } catch (error) {
            console.error('Error saving header preferences', error);
        }
    };

    const handleSaveColumnOrder = async () => {
        try {
            const columnOrder = columns.map(column => column.accessor);
            const response = await instance.post('/users/preferences/save', {
                module: 'Reports',
                record_id: reportId,
                preferences: {
                    column_order: columnOrder
                }
            }, { withCredentials: true });

            if (response.status === 200) {
                console.log('Column order saved successfully');
            } else {
                console.error('Error saving column order', response);
            }
        } catch (error) {
            console.error('Error saving column order', error);
        }
    };



    return (
        <>
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle sx={{ alignSelf: 'center' }}>Select a report</DialogTitle>
        <DialogActions
        sx={{
            display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '1rem',
                padding: '1rem',
        }}
        >
        {currentLinks.map((link) => (
            <Button key={link.label} onClick={handleCloseDialog} component={Link} to={link.path}>
            {link.label}{link.name}
            </Button>
        ))}
        </DialogActions>
        </Dialog>
        <Button onClick={() => setIsReordering(!isReordering)} sx={{ marginBottom: '1rem' }}>
        <TuneIcon />{isReordering ? 'Stop Reordering' : 'Reorder Columns'}
        </Button>
        {isReordering && (
            <Button onClick={handleSaveColumnOrder} sx={{ marginBottom: '1rem' }}>
            Save Column Order
            </Button>
        )}
        {auth.is_admin && (
            <>
            <Button onClick={handleEditHeadersClick} sx={{ marginBottom: '1rem' }}>
            <DriveFileRenameOutlineIcon /> Edit Headers
            </Button>
            <Button onClick={handleRemovePreferences} sx={{ marginBottom: '1rem' }}>
            <DeleteIcon />Remove Preferences
            </Button>
            <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
            <DialogTitle>Edit Column Headers</DialogTitle>
            <DialogContent>
            {columns.map((column) => (
                <TextField
                key={column.accessor}
                label={column.Header}
                variant="outlined"
                fullWidth
                margin="dense"
                value={newHeaders[column.accessor] || column.Header}
                onChange={(e) => setNewHeaders({
                    ...newHeaders,
                    [column.accessor]: e.target.value
                })}
                />
            ))}
            </DialogContent>
            <DialogActions>
            <Button onClick={() => setEditDialogOpen(false)} >
            Cancel
            </Button>
            <Button onClick={handleSaveHeaders}  variant="contained">
            Save
            </Button>
            </DialogActions>
            </Dialog>
            </>
        )}
        <DndProvider backend={HTML5Backend}>
        <div
        style={{
            overflowX: 'auto',
                border: '1px solid #ccc',
                borderRadius: '4px',
        }}
        >
        <TableContainer component={Paper}>
        <Table hoverRow sx={{ width: 'auto', borderCollapse: 'collapse' }}>
        <TableHead>
        <TableRow sx={{ zIndex: 2 }}>
        {columns.map((column, index) => (
            <DraggableColumn
            key={column.accessor}
            column={column}
            index={index}
            handleRequestSort={handleRequestSort}
            moveColumn={moveColumn}
            isReordering={isReordering}
            isFirst={index === 0}
            isDarkMode={isDarkTheme}
            />
        ))}
        </TableRow>
        </TableHead>
        <TableBody>
        {stableSort(data, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                {columns.map((column, columnIndex) => (
                <TableCell
                    key={`${rowIndex}-${column.accessor}`}
                    style={{
                        position: columnIndex === 0 ? 'sticky' : 'relative',  // Sticky position for the first column
                        left: columnIndex === 0 ? 0 : 'auto',  // Fix it to the left
                        zIndex: columnIndex === 0 ? 1 : 'auto',  // Ensure the first column stays on top
                        backgroundColor: columnIndex === 0 ? (isDarkTheme ? '#1e1e1e' : 'white') : 'inherit', // Sticky background for first column

                    }}
                    >
              {renderCellContent(column, row)}
            </TableCell>
                ))}
                </TableRow>
            ))}
        </TableBody>
        </Table>
        </TableContainer> 
        </div>
        </DndProvider>
        <TablePagination
        component="div"
        count={data.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </>
    );
}

export default DynamicTable;
